import { toggleClassName } from "../../../../utils/disabled";
import { TableWrapper } from "../../../common";

export const Managers = () => {
  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h3
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Руководство
      </h3>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>ФИО</td>
              <td>Должность</td>
              <td>Контактные телефоны</td>
              <td>Адреса электронной почты</td>
            </tr>
            <tr itemProp="rucovodstvo">
              <td itemProp="fio">Ахмадеева Фарида Шариповна </td>
              <td itemProp="post">Директор</td>
              <td itemProp="telephone">8 (347) 215-14-00 доб. 6</td>
              <td itemProp="email">
                {" "}
                <a href="mailto:uf-vsuwt@uf-vsuwt.ru">
                  uf-vsuwt@uf-vsuwt.ru
                </a>{" "}
              </td>
            </tr>
            <tr itemProp="rucovodstvoZam">
              <td itemProp="fio">Мусина Гульфия Ириковна </td>
              <td itemProp="post">Заместитель директора по учебной работе</td>
              <td itemProp="telephone">8 (347) 215-14-00 доб. # 312</td>
              <td itemProp="email">
                {" "}
                <a href="mailto:pur@uf-vsuwt.ru">pur@uf-vsuwt.ru</a>{" "}
              </td>
            </tr>
            <tr itemProp="rucovodstvoZam">
              <td itemProp="fio">Акбашева Альбина Галимзяновна </td>
              <td itemProp="post">
                Заместитель директора по воспитательной работе
              </td>
              <td itemProp="telephone">8 (347) 215-14-00 доб. # 320</td>
              <td itemProp="email">
                {" "}
                <a href="mailto:pvr@uf-vsuwt.ru">pvr@uf-vsuwt.ru</a>{" "}
              </td>
            </tr>
            <tr itemProp="rucovodstvoZam">
              <td itemProp="fio">Жоссан Елена Евгеньевна </td>
              <td itemProp="post">Главный бухгалтер </td>
              <td itemProp="telephone">8 (347) 215-14-00 доб. 1</td>
              <td itemProp="email">
                {" "}
                <a href="mailto:buh@uf-vsuwt.ru">buh@uf-vsuwt.ru</a>{" "}
              </td>
            </tr>
            <tr itemProp="rucovodstvoZam">
              <td itemProp="fio">Шабалина Елена Павловна</td>
              <td itemProp="post">
                Начальник центра организационно-правовой работы{" "}
              </td>
              <td itemProp="telephone">8 (347) 215-14-00 доб. # 311</td>
              <td itemProp="email">
                {" "}
                <a href="mailto:copr@uf-vsuwt.ru">copr@uf-vsuwt.ru</a>{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </div>
  );
};
