import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import astronomia from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Астрономия СВ РУП 23 2021 гп.pdf";
import bj_sv_rup from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП БЖ СВ РУП 23 РУП 23 2021 гп.pdf";
import injenern_graf from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Инженерная графика СВ РУП 23 2021 гп.pdf";
import inostr_v_prof from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Иностранный в профессиональной деятельности 26.02.03 РУП 23 2021гп.pdf";
import in_1 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Иностранный язык 1 курс  26.02.03, РУП 23 2021 г.п..pdf";
import inform2 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Информатика 23.02.03.РУП 23 2021гп.pdf";
import istor1 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП История 1 курс 26.02.03, 2021 г.п..pdf";
import istor2 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП История 2 курс 26.02.03, 2021 г.п..pdf";
import literatura from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Литература 26.02.03, 2021 г.п..pdf";
import matem1 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Математика  26.02.03,РУП 23  2021 СВ 1 курс.pdf";
import matem2 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Математика 26.02.03  РУП 23 2021 гп.pdf";
import material from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Материаловедение 26.02.03, РУП 23 2021 г.п..pdf";
import metrol from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Метрология и стандартизация 26.02.03. РУП 23 2021 гп.pdf";
import mehanika from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Механика 26.02.03. РУП 23 2021гп.pdf";
import obj from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП ОБЖ 26.02.03, РУП 23 2021 г.п..pdf";
import osnov_filos1 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Основы философии 26.02.03, 2021 г.п..pdf";
import osnov_fin from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Основы финансовой грамотности 26.02.03, РУП 23 2021 г.п..pdf";
import pm12 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП ПМ 1 26.02.03, РУП 23 2021 г.п..pdf";
import pm2 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП ПМ 2 СВ 26.02.03, РУП 23 2021 г.п..pdf";
import pm3 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП ПМ 3 СВ 26.02.03, 2021 г.п..pdf";
import pm4 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП ПМ 4 26.02.03.РУП 23 2021 гп.pdf";
import pm5 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП ПМ 5 26.02.03, 2021 г.п..pdf";
import psiholog from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Психология общения 26.02.03, РУП 23  2021 г.п..pdf";
import rodnoy from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Родной язык 26.02.03 РУП 23 2021 г.п..pdf";
import russkiy from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Русский язык 26.02.03, РУП 23 2021 г.п..pdf";
import tus from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП ТУС 26.02.03, РУП 23  2021 г.п..pdf";
import fizika from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Физика 26.02.03  2021 гп.pdf";
import fizra1 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Физкультура  26.02.03, РУП 23 2021 г.п..pdf";
import fizra2 from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Физкультура 26.02.03,  РУП 23 2021 г.п.  2-5 курс.pdf";
import chimia from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Химия 26.02.03, РУП 23 2021 г.п..pdf";
import electronica from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП Электроника и электротехника26.02.03. РУП 23  2021пг.pdf";
import rpeop from "../../../../assets/pdf/educationPage/260203/СВ 2021 гп/4. Рабочие программы/РП ЭОП 26.02.03,  РУП 23 2021 г.п..pdf";

export const Navigation2021 = () => {
  return (
    <section className="workProgramm__wrapper">
      <h1>
        Основная профессиональная образовательная программа среднего
        профессионального образования для специальности 26.02.03 "Судовождение"
        (углубленная подготовка) (год набора 2021)
      </h1>
      <h2>Рабочие программы</h2>
      <table>
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Наименование дисциплины</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={astronomia}
                target={"_blank"}
                rel="noreferrer"
              >
                Астрономия
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={bj_sv_rup}
                target={"_blank"}
                rel="noreferrer"
              >
                Безопасность жизнедеятельности
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>3</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={injenern_graf}
                target={"_blank"}
                rel="noreferrer"
              >
                Инженерная графика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inostr_v_prof}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык в профессиональной деятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={in_1}
                target={"_blank"}
                rel="noreferrer"
              >
                Иностранный язык 1 курс
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>6</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={inform2}
                target={"_blank"}
                rel="noreferrer"
              >
                Информатика 1 курс
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>7</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istor1}
                target={"_blank"}
                rel="noreferrer"
              >
                История 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={istor2}
                target={"_blank"}
                rel="noreferrer"
              >
                История 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={literatura}
                target={"_blank"}
                rel="noreferrer"
              >
                Литература
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matem1}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={matem2}
                target={"_blank"}
                rel="noreferrer"
              >
                Математика 2 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={material}
                target={"_blank"}
                rel="noreferrer"
              >
                Материаловедение
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={metrol}
                target={"_blank"}
                rel="noreferrer"
              >
                Метрология и стандартизация
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>14</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={mehanika}
                target={"_blank"}
                rel="noreferrer"
              >
                Механика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>15</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={obj}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы безопасности жизнидеятельности
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>16</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={osnov_filos1}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы философии
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>17</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={osnov_fin}
                target={"_blank"}
                rel="noreferrer"
              >
                Основы финансовой грамотности в предпринимательской деятельности
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>18</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm12}
                target={"_blank"}
                rel="noreferrer"
              >
                Управление и эксплуатация судна с правом эксплуатации судовых
                энергетических установок
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>19</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm2}
                target={"_blank"}
                rel="noreferrer"
              >
                Обеспечение безопасности плавания
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>20</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm3}
                target={"_blank"}
                rel="noreferrer"
              >
                Обработка и размещение груза
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>21</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm4}
                target={"_blank"}
                rel="noreferrer"
              >
                Анализ эффективности работы судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>22</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={pm5}
                target={"_blank"}
                rel="noreferrer"
              >
                Выполнение работ по одной или нескольким профессиям рабочих
                должностям служащих
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>23</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={psiholog}
                target={"_blank"}
                rel="noreferrer"
              >
                Психология общения
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>24</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={rodnoy}
                target={"_blank"}
                rel="noreferrer"
              >
                Родной язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>25</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={russkiy}
                target={"_blank"}
                rel="noreferrer"
              >
                Русский язык
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>26</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={tus}
                target={"_blank"}
                rel="noreferrer"
              >
                Теория устройства судна
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>27</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizika}
                target={"_blank"}
                rel="noreferrer"
              >
                Физика
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>28</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizra1}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура 1 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>29</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={fizra2}
                target={"_blank"}
                rel="noreferrer"
              >
                Физическая культура 2-5 курс
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>30</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={chimia}
                target={"_blank"}
                rel="noreferrer"
              >
                Химия
              </a>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>31</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={electronica}
                target={"_blank"}
                rel="noreferrer"
              >
                Электротехника и электроника
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>32</td>
            <td>
              {" "}
              <PDF height={"25px"} width={"25px"} />
              <a
                itemProp="educationRpd"
                href={rpeop}
                target={"_blank"}
                rel="noreferrer"
              >
                Экологические основы природопользования
              </a>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
